import React from 'react';
import MenuLink from '../common/menu-link.component.js';
import SourceForm from './source-form.component.js';
import {includes} from 'lodash';

export default class SourceFormGroup extends React.Component {
  render() {
    const {group, expandedFormGroups, indentationLevel, searchString, groupVisibility, parentForceVisible} = this.props;
    const {hasVisibleChild, formsForceVisibility, formsCouldBeVisible, forceChildrenVisible, subGroupCouldBeVisible} = groupVisibility[group.id];
    const childGroups = hasVisibleChild
      ?
        group.groups.filter(childGroup => groupVisibility[childGroup.id].hasVisibleChild || groupVisibility[childGroup.id].forceChildrenVisible)
      :
        group.groups;

    const dontShowGroup = !!searchString
      ?
        // Logic for determining whether to show the group when the search is active
        childGroups.length === 0 && !formsCouldBeVisible || (!hasVisibleChild && !parentForceVisible && !formsForceVisibility && !forceChildrenVisible)
      :
        // Logic for determining whether to show the group when nothing has been searched
        !formsCouldBeVisible && !subGroupCouldBeVisible

    if (dontShowGroup) {
      return null;
    }

    const groupIsExpanded = includes(expandedFormGroups, group.id);
    const isExpanded = forceChildrenVisible || hasVisibleChild || groupIsExpanded;

    return (
      <div data-testid="source-form-group">
        <MenuLink
          onClick={toggleGroupExpanded.bind(this)}
          icon={isExpanded ? "cps-icon-folder-open" : "cps-icon-folder"}
          text={group.name}
          indentationLevel={indentationLevel}
          searchString={searchString}
        />
        {isExpanded &&
          <div>
            {
              childGroups.map(group => (
                <SourceFormGroup
                  {...this.props}
                  key={group.id}
                  group={group}
                  indentationLevel={indentationLevel + 1}
                  parentForceVisible={forceChildrenVisible}
                />
              ))
            }
            {
              group.forms.map(form => (
                <SourceForm
                  {...this.props}
                  key={form.meta.formId + form.index}
                  formId={form.meta.formId}
                  sourceForm={form}
                  indentationLevel={indentationLevel + 1}
                  parentForceVisible={forceChildrenVisible || groupIsExpanded}
                />
              ))
            }
          </div>
        }
      </div>
    );
  }
}

function toggleGroupExpanded(evt) {
  evt.preventDefault();
  const {group, expandedFormGroups, expandGroup, collapseGroup} = this.props;

  const action = includes(expandedFormGroups, group.id) ? collapseGroup : expandGroup;
  action(group.id);
}
