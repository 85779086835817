import React from 'react';
import {Dialog} from 'primary-navbar!sofe';
import styles from './add-step.styles.css';
import {deleteSection} from './program-sections.resource.js';
import {catchAsyncStacktrace} from 'auto-trace';
import {CprButton} from 'canopy-styleguide!sofe';

export default class DeleteStep extends React.Component {
  constructor() {
    super();
    this.state = {
      deleting: false,
    };
  }
  render() {
    const {cancel, stepId, clientId, engagementId, program} = this.props;
    return (
      <Dialog>
        <div className="cps-modal">
          <div className="cps-modal__screen" />
          <div className="cps-modal__dialog cps-card cps-card__height-3">
            <div className="cps-card__header cps-subheader-sm">
              <span>
                Delete step
              </span>
              <a className={`cps-modal__dialog__close cps-icon cps-icon-close ${styles.alignCloseBtn}`} onClick={cancel} data-testid="x-button" />
            </div>
            <div className="cps-card__body">
              You're about to delete this step. All the files and comments associated with this step will be deleted. This cannot be undone.
            </div>
            <div className="cps-modal__dialog__actions">
              <CprButton
                actionType="primary"
                onClick={() => deleteStep.call(this)}
                disableOnClick={true}
                showLoader={this.state.deleting}
              >
                Delete step
              </CprButton>
              <CprButton actionType="flat" onClick={cancel}>
                Close
              </CprButton>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

function deleteStep() {
  this.setState({deleting: true});

  const {clientId, engagementId, stepId, program, cancel} = this.props;

  deleteSection(clientId, engagementId, stepId)
  .then(() => {
    program.sections = program.sections.filter(section => {
      return section.id !== stepId;
    });

    // close the modal
    cancel();

    if (window.location.href.indexOf(`/section/${stepId}`) >= 0) {
      window.location.href = `#/taxes/client/${clientId}/engagements/${engagementId}/summary`;
    }
  })
  .catch(catchAsyncStacktrace());
}
