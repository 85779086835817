import React from 'react';
import WorkflowTab from '../workflow/workflow-tab.component.js';
import {CprLoader} from 'canopy-styleguide!sofe';
import FormsTab from '../forms/forms-tab.component.js';
import FetchSMEVersion from '../forms/fetch-sme-version.component.js';
import FetchSourceForms from '../forms/fetch-source-forms.component.js';
import WithActiveSourceForm from '../forms/with-active-source-form.component.js';

export default class ActiveTab extends React.Component {
  render() {
    const SelectedTab = this.props.activeTab === FormsTab ? FormsTabWithData : this.props.activeTab;

    return this.props.searchString
      ?
        <div>
          <WorkflowTab {...this.props} />
          <FormsTabWithData {...this.props} />
        </div>
      :
        <SelectedTab {...this.props} />
  }
}

function FormsTabWithData(props) {
  return (
    <WithActiveSourceForm data-testid="forms-tab-with-data">
      {({activeSourceForm}) => (
        <FetchSMEVersion>
          {({smeVersion, smeRevision}) => smeVersion && (
            <FetchSourceForms
              clientId={props.clientId}
              engagementId={props.engagementId}
              engagement={props.engagement}
              smeVersion={smeVersion}
              smeRevision={smeRevision}
              activeSourceForm={activeSourceForm}
            >
              {({sourceFormGroups, addFormMultiple}) => sourceFormGroups ? (
                <FormsTab
                  {...props}
                  addFormMultiple={addFormMultiple}
                  sourceFormGroups={sourceFormGroups}
                  smeVersion={smeVersion}
                  smeRevision={smeRevision}
                  activeSourceForm={activeSourceForm}
                />
              ) : <div style={{marginTop: 24}}>
                <CprLoader />
              </div>}
            </FetchSourceForms>
          )}
        </FetchSMEVersion>
      )}
    </WithActiveSourceForm>
  )
}
