import React from "react";
import { SMEToggleVersionComponent } from "sme-version!sofe";
import { UserTenantProps } from "cp-client-auth!sofe";

import styles from "./toggle-sme-version.styles.css";

@UserTenantProps({ permissions: { hasSmePermissions: 'sme' } })
export default class ToggleSMEVersion extends React.Component {
  render() {
    return this.props.permissions?.hasSmePermissions ? (
      <div className={`${styles.root}`} data-testid="toggle-sme-version">
        <SMEToggleVersionComponent engagementType={this.props.engagementType} />
      </div>
    ) : null;
  }
}
