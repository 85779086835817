import React from 'react';
import {getEngagementObservable} from './engagements.resource.js';
import {asyncStacktrace, catchSyncStacktrace} from 'auto-trace';

export default class FetchEngagement extends React.Component {
  constructor() {
    super();
    this.state = {
      engagement: null,
    };
    this.boundCheckFetchEngagement = checkFetchEngagement.bind(this);
  }
  componentDidMount() {
    this.boundCheckFetchEngagement(this.props);
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.boundCheckFetchEngagement(this.props);
    }
  }
  render() {
    return <div style={{height: '100%'}} data-testid="fetch-engagement">{this.props.children({engagement: this.state.engagement})}</div>
  }
  componentWillUnmount() {
    if (this.engagementSubscription) {
      this.engagementSubscription.unsubscribe();
    }
  }
}

function checkFetchEngagement(props) {
  // This is to prevent race condition with single-spa:routing-event from with-url-params.component.js
  if (!props.engagementId || !props.clientId) return;

  if (props.newUrlParams) {
    if (this.engagementSubscription) {
      this.engagementSubscription.unsubscribe();
    }

    this.engagementSubscription = getEngagementObservable(props.clientId, props.engagementId)
      .subscribe(
        engagement => this.setState({engagement}),
        asyncStacktrace(err => {
          if (err.status === 404 || err.status === 403) {
            console.info(`/api/clients/{ID}/engagements/{ID} returned ${err.status}, redirecting`);
            history.replaceState('', '', `/#/${err.status}`)
          } else catchSyncStacktrace(err)
        })
      );
  }
}
